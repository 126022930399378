
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDivider from "../components/atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../resources/images/right-arrow.svg"
import Benefits from "../components/benefits"

import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function MobilneAplikacie() {

  const data = useStaticQuery(graphql`{
  webRef: file(relativePath: {eq: "gyg_show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef2: file(relativePath: {eq: "tjlforms-box.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  webRef3: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef4: file(relativePath: {eq: "tasty-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)



  return (
    <Layout>
      <SEO title="Big Data" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Robíme <span style={{color: "#F94166"}}>Big Data.</span></h1></div>
            <p>Pracujeme s Big Data za rôznymi účelmi, najmä je to segmentácia zákazníkov, predikcie tržieb, odporúčanie produktu a detekcia podvodov - pomáhame si strojovým učením. Dáta spracujeme navrhneme konkrétne modely pre konkrétne výsledky. Navrhneme Vám zlepšenia vo Vašom biznise, ktoré budú používať tieto údaje.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      
      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="Clear process" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>Pozrite si ako postupujeme.</h2>
            </div>
            <div className="col-6 section-subtitle-black"><p>Máme jasný štandardizovaný proces, ktorý zabezpečí dodržanie termínov a prehľad.</p></div>
            
          </div>

          <div className="row mt40">
          
            <div className="col-6 process-list">
              <div className="number">1.</div>
              <h3>Analýza a čistenie dát</h3>
              <div className="regular-text">Na dáta použijeme softvérové nástroje, ktoré nám pomôžu odstrániť nežiadúce dáta, doplniť chýbajúce údaje, rozdeliť dáta alebo spojiť ak je to potrebné.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">2.</div>
              <h3>Návrh modelov</h3>
              <div className="regular-text">Z dát, ktoré máme k dispozícii navrhujeme modely, ktoré využíjú dáta a prinesú výsledky.</div>
            </div>
          </div>
          <div className="row mt20">
            
            <div className="col-6 process-list">
              <div className="number">3.</div>
              <h3>Implementácia modelov</h3>
              <div className="regular-text">Navrhnuté modely ďalej použijeme na testovacích dátach a vyhodnocujeme podstatu navrhovaných modelov. Vybrané funkčné modely ďalej implementujeme na reálne dáta.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">4.</div>
              <h3>Vyhodnotenie výsledkov</h3>
              <div className="regular-text">Výsledky, ktoré získame analyzujeme a vyhodnocujeme. Následne navrhujeme ich využitie v informačných systémoch, reklamách, výpočtoch, predikciách a podobne. Ak z výsledkov analýzy dát vyplynula možnosť využiť softvér navrhujeme a vyvíjame softvér na mieru podľa postupu v rámci webových aplikácií.</div>
            </div>
          </div>
        

      
        </div>
      </div>

      

      <div className="section-pd section-pd black-text white-bg-cut">
      <div className="container">
        <SectionDivider name="references" white={false}/>
        <div className="row">
          <div className="col-4 section-heading">
            <h2>Pozrite sa na čom sme pracovali.</h2>
          </div>
          <div className="col-4 section-subtitle-black mt20">
            <p>Robili sme na zaujímavých projektoch, projekty, ktoré pomáhajú ľuďom. Veď presvedčte sa sami.</p>
          </div>
        </div>

        <div className="row mt40">

        <div className="col-6">
          <AniLink cover direction="left" to="/referencie/gyg" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Softvér na mieru" />  
              <div className="ref-button">
                Softvér na mieru
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef2.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Webová aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
        <div className="row mt40">

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/doku" bg="#000" duration={1}><div className="ref-card">
              <GatsbyImage
                image={data.webRef3.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Web aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div></AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef4.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Mobilná aplikácia" />  
              <div className="ref-button">
                Mobilná aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
          
           
      </div>
          
    </div>
    <Benefits />
    </Layout>
  );

}
